<template>
  <div style="margin-top:3%">
    <v-container fluid>
      <v-row justify="space-around">
        <v-col cols="12" xl="4" lg="5">
          <v-card class="elevation-2">
            <v-card-actions style="padding-top:2%;">
              <div style="margin-left:20px">
                <h1>측정기 선택</h1>
              </div>

            </v-card-actions>
            <v-container fluid>
              <v-data-table v-model="selected" :headers="headers" :items="devices" item-key="deviceId" show-select/>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" xl="6" lg="7">

          <v-card class="elevation-2">
            <v-card-text style="padding-bottom:0px">
              
                <v-subheader style="font-weight: bold">조회 옵션</v-subheader>
                <v-row align="center" justify="center">

                  <v-col cols="12" xl="5" lg="3" class="noPadding">
                    <v-radio-group v-model="criteria" row dense>
                      <v-radio label="일 평균" value="day"></v-radio>
                      <v-radio label="시간 평균" value="time"></v-radio>
                      <v-radio label="분 평균" value="min"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col v-if="airData.length !== 0 && loaded === true" cols="12" xl="3" lg="3" class="noPadding">
                    <v-combobox v-model="deviceSelected" :items="aliasList" label="선택 리스트" type="button"
                      v-on:change="chooseChartData(currentTab)" />
                  </v-col>

                  <v-col cols="12" xl="2" lg="3" class="noPadding">
                    <v-btn block class="white--text" color="indigo darken-2" @click.native="getChartData">그래프 출력</v-btn>
                  </v-col>

                  <v-col cols="12" xl="2" lg="3" class="noPadding">
                    <v-btn block class="white--text" color="indigo darken-2" @click.native="excelDownload">엑셀 다운로드</v-btn>
                  </v-col>

                </v-row>
        
              <v-subheader style="font-weight: bold">조회 기간</v-subheader>
              <v-row align="center" justify="center">

                <v-col cols="12" xl="3" lg="3" class="noPadding">
                  <v-menu ref="startDateMenu" :close-on-content-click="false" v-model="startDateMenu" :nudge-right="40"
                    :return-value.sync="startDate" transition="scale-transition" offset-y min-width="10px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-bind="attrs" v-on="on" v-model="startDate" label="시작 날짜"
                        prepend-icon="mdi-calendar" readonly></v-text-field>
                    </template>
                    <v-date-picker v-model="startDate" @input="$refs.startDateMenu.save(startDate)"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" xl="1" lg="2" v-if="criteria !== 'day'" class="noPadding">
                  <v-select v-model="startHour" :items="hourList" label="시" />
                </v-col>

                <v-col cols="12" xl="1" lg="2" v-if="criteria === 'min'" class="noPadding">
                  <v-select v-model="startMin" :items="minList" label="분" />
                </v-col>

                <v-col cols="12" xl="1" lg="12" style="text-align:center; padding-top:0px; padding-bottom:0px">
                  <h1>~</h1>
                </v-col>

                <v-col cols="12" xl="3" lg="3"   class="noPadding">
                  <v-menu ref="endDateMenu" :close-on-content-click="false" v-model="endDateMenu" :nudge-right="40"
                    :return-value.sync="endDate" transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-bind="attrs" v-on="on" v-model="endDate" label="종료 날짜" prepend-icon="mdi-calendar"
                        readonly></v-text-field>
                    </template>
                    <v-date-picker v-model="endDate" @input="$refs.endDateMenu.save(endDate)"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" xl="1" lg="2" v-if="criteria !== 'day'"  class="noPadding">
                  <v-select v-model="endHour" :items="hourList" label="시" />
                </v-col>

                <v-col cols="12" xl="1" lg="2" v-if="criteria === 'min'"  class="noPadding">
                  <v-select v-model="endMin" :items="minList" label="분" />
                </v-col>

              </v-row>

            </v-card-text>

            <v-container fluid style="padding-top:0px">

              <div id="main" v-if="loaded === true">
                <v-layout column style="height: 40vh">
                  <v-flex style="overflow: auto">
                    <v-tabs color="indigo darken-2" fixed-tabs>
                      <v-tab v-on:click="chooseChartData(1)" style="font-weight:bold;">극초미세먼지</v-tab>
                      <v-tab v-on:click="chooseChartData(2)" style="font-weight:bold;">초미세먼지</v-tab>
                      <v-tab v-on:click="chooseChartData(3)" style="font-weight:bold;">미세먼지</v-tab>
                      <v-tab v-on:click="chooseChartData(4)" style="font-weight:bold;">포름알데히드</v-tab>
                      <v-tab v-on:click="chooseChartData(5)" style="font-weight:bold;">이산화탄소</v-tab>
                      <v-tab v-on:click="chooseChartData(6)" style="font-weight:bold;">온도</v-tab>
                      <v-tab v-on:click="chooseChartData(7)" style="font-weight:bold;">습도</v-tab>
                    </v-tabs>

                    <v-flex>
                      <div>
                        <br>
                        <LineChart style="display: block; height: 30vh;" :chartData="chartData" :options="options" />
                      </div>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </div>
              <div class="text-lg-center pa-5" style="width: 100%;" v-else-if="loaded === false">
                <v-progress-circular width="7" size="70" indeterminate color="red" />
              </div>
            </v-container>

          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>



<script>
  import LineChart from '../plugins/chart'
  const moment = require('moment')

  export default {

    components: {
      LineChart
    },

    created() {
      this.getDevices();
      this.initTimeList();
    },
    watch: {
      selected(val) {
        this.deviceChooseCnt = val.length
      },
      criteria(val) {
        if (val === 'day' || val === 'time') {
          this.startMin = '00'
          this.endMin = '00'
        }
        if (val === 'day') {
          this.startHour = '00'
          this.endHour = '00'
        }
      }
    },
    methods: {

      async chkDateTime() {

        var rtVal = false;

        var start = new Date(Number(this.startDate.split('-')[0]), Number(this.startDate.split('-')[1]) - 1, Number(this.startDate.split('-')[2]), Number(this.startHour), Number(this.startMin), 0)
        var end = new Date(Number(this.endDate.split('-')[0]), Number(this.endDate.split('-')[1]) - 1, Number(this.endDate.split('-')[2]), Number(this.endHour), Number(this.endMin), 0)
        if (start > end) {
          this.$dialog.error({
            text: '날짜를 올바르게 선택해주세요.',
            title: 'Error'
          })
        } else if (this.selected.length < 1) {
          this.$dialog.error({
            text: '기기를 선택해주세요.',
            title: 'Error'
          })
        } else {

          this.params.startTime = moment(start.getTime()).format(this.fm)

          if (this.criteria === 'day') {
            this.params.endTime = moment(end.getTime()).add(1, 'd').format(this.fm)
          } else if (this.criteria === 'time') {
            this.params.endTime = moment(end.getTime()).add(1, 'h').format(this.fm)
          } else {
            this.params.endTime = moment(end.getTime()).add(1, 'm').format(this.fm)
          }

          rtVal = true;
        }

        return rtVal;
      },
      initTimeList() {
        for (var i = 0; i < 60; i++) {

          if (i < 10) {

            this.hourList.push('0' + String(i))
            this.minList.push('0' + String(i))
          } else {

            if (i < 24) {
              this.hourList.push(String(i))
            }
            this.minList.push(String(i))
          }
        }
      },

      async getChartData() {

        if (await this.chkDateTime()) {

          this.loaded = false
          this.aliasList = [], this.airData = []

          this.selected.forEach((value, index) => {

            this.params.deviceId = value.deviceId
            
            this.$axios.get("/msg/stat/" + this.criteria, {
              headers: this.params
            }).then(res => {

              if (res.data.length) {
                this.airData.push(res.data)
                this.aliasList.push(value.alias)
              } else {
                this.$dialog.warning({
                  text: `시리얼 번호 : ${value.deviceId} <br> 기기번호 : ${value.mgmtId} <br> 별칭 : ${value.alias}`,
                  title: '조회된 데이터가 없습니다.'
                })
              }

              if (index === this.selected.length - 1) {

                if (this.airData.length === 0) {
                  this.loaded = null
                } else {

                  this.deviceSelected = this.aliasList[0]
                  this.chooseChartData(1)
                  this.loaded = true
                }
              }
            }).catch(err => {
              console.log(err)
            })
          })
        }
      },

      async excelDownload() {

        if (await this.chkDateTime()) {

          this.loaded = null

          this.selected.forEach((value) => {

            this.params.deviceId = value.deviceId

            this.$axios.get("/msg/excel/" + this.criteria, {
              headers: this.params
            }).then(res => {

              if (res.data.code === 200) {
                var currentData = moment().format('YYMMDD HHmm')
                const url = window.URL.createObjectURL(new Blob(['\ufeff' + res.data.data], {
                  type: 'text/csv;charset=utf-8;'
                }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('style', 'display:none');
                link.setAttribute('download', value.alias + '_' + currentData + '.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
              } else {
                this.$dialog.warning({
                  text: `시리얼 번호 : ${value.deviceId} <br> 기기번호 : ${value.mgmtId} <br> 별칭 : ${value.alias}`,
                  title: '조회된 데이터가 없습니다.'
                })
              }
            }).catch(err => {
              console.log(err)
            })
          })
        }
      },

      getDevices() {
        this.$axios.get("/device/info").then((res) => {
            this.devices = res.data.data
          })
          .catch((err) => {
            console.error(err)
          })
      },
      chooseChartData(air) {

        var device = 0;

        this.airData.forEach((value, index) => {

          if (value[0].alias === this.deviceSelected) {
            device = index
          }
        })
        this.currentTab = air
        const airList = ['극초미세먼지', '초미세먼지', '미세먼지', '포름알데히드', '이산화탄소', '온도', '습도'];

        var labelsList = [],
          dataList = [],
          divList = [];

        this.airData[device].forEach((value) => {
          labelsList.push(value.date)

          if (air === 1) {
            dataList.push(value.avg1)
          } else if (air === 2) {
            dataList.push(value.avg25)
            divList.push(25)
          } else if (air === 3) {
            dataList.push(value.avg100)
            divList.push(50)
          } else if (air === 4) {
            dataList.push(value.avgHcho)
            divList.push(600)
          } else if (air === 5) {
            dataList.push(value.avgCo2)
            divList.push(1000)
          } else if (air === 6) {
            dataList.push(value.avgTemp)
          } else if (air === 7) {
            dataList.push(value.avgHumi)
          }
        })

        this.chartData = {
          labels: labelsList,
          datasets: [{
            label: airList[air - 1],
            borderColor: '#264476',
            // backgroundColor: '#264476',
            fill: true,
            data: dataList,
          }]
        }

        if (air > 1 && air < 6) {
          this.chartData.datasets.push({
            label: "기준치(나쁨)",
            borderColor: '#f87979',
            fill: false,
            data: divList,

          })
        }
      }
    },
    data() {
      return {

        deviceChooseCnt: 0,
        chartData: null,
        airData: [],

        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                stepSize: 10,
                maxTicksLimit: 5,
                beginAtZero: true,
              }
            }],
            xAxes: [{
              ticks: {
                stepSize: 5,
                maxTicksLimit: 5,
              }
            }]
          },
          elements: {
            point: {
              radius: 1,
              hoverRadius: 4,
            },
          },
          tooltips: {
            mode: 'index',
            intersect: false,
            position: 'nearest',
          },
          hover: {
            mode: 'index',
            intersect: false
          },
          legend: {
            display: false
          }
        },

        fm: 'YYYY-MM-DD HH:mm:ss',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        endDateMenu: false,
        startDateMenu: false,

        criteria: 'day',
        startHour: '00',
        endHour: '00',
        startMin: '00',
        endMin: '00',

        hourList: [],
        minList: [],

        currentTab: 1,

        aliasList: [],
        deviceSelected: null,

        loaded: null,

        selected: [],
        devices: [],

        params: {
          deviceId: null,
          startTime: null,
          endTime: null
        },

        headers: [{
            text: '시리얼 번호',
            align: 'center',
            sortable: false,
            value: 'deviceId'
          },
          {
            text: '기기 번호',
            align: 'center',
            sortable: false,
            value: 'mgmtId'
          },
          {
            text: '별칭',
            align: 'center',
            sortable: false,
            value: 'alias'
          }
        ],
      }
    }
  }
</script>

<style scoped>

::v-deep .v-data-table th{ 
  background-color:#ebebeb;
  font-weight:bold;
  color:black !important;
  font-size: 18px !important;
}

.noPadding {
  padding-top: 0px;
  padding-bottom: 0px;
}


</style>






