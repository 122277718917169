<template>
  <div id="app">
    <v-app>
      <v-card class="overflow-hidden">
        <v-app-bar color="indigo darken-2" dark>
          <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs}">
              <img @click="navi('/main')" :src="require('@/assets/main_logo.png')"
                style="height:100%; margin-right:10px" v-bind="attrs" v-on="on" />
            </template>
            <span> Home </span>
          </v-tooltip>
          <v-toolbar-title class="white--text">공기질 통합관리 시스템</v-toolbar-title>
          <v-spacer></v-spacer>

          <h4 v-if="loginStatus() === true">{{this.$store.state.corpName}} / 잔여 계악 기간:
            {{this.$store.state.endContract}}일 </h4>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs}">
              <v-btn v-bind="attrs" v-on="on" icon label="로그아웃" @click="click_logout">
                <v-icon>mdi-logout</v-icon>
              </v-btn>

            </template>
            <span> Logout </span>
          </v-tooltip>

        </v-app-bar>

        <v-navigation-drawer v-model="drawer" absolute temporary>
          <v-list nav dense>
            <v-list-item-group active-class="deep-purple--text text--accent-4">
              <v-list-item @click="navi('/main')">
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-title>메인</v-list-item-title>
              </v-list-item>

              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>마이페이지</v-list-item-title>
                </template>
                <v-list-item @click="navi('/editAccount')">
                  <v-list-item-title>내 정보 수정</v-list-item-title>
                </v-list-item>
                <v-list-item @click="navi('/withDrawal')">
                  <v-list-item-title>회원 탈퇴</v-list-item-title>
                </v-list-item>
              </v-list-group>
              <v-list-item @click="navi('/device')">
                <v-list-item-icon>
                  <v-icon>mdi-cellphone</v-icon>
                </v-list-item-icon>
                <v-list-item-title>측정기 관리</v-list-item-title>
              </v-list-item>

              <v-list-item @click="navi('/stat')">
                <v-list-item-icon>
                  <v-icon>mdi-chart-line</v-icon>
                </v-list-item-icon>
                <v-list-item-title>통계 및 엑셀 다운로드</v-list-item-title>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>

        <v-main>
          <v-container fluid style="min-height: 1000px; height: auto;">
            <router-view></router-view>
          </v-container>
        </v-main>
      </v-card>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods:{
    loginStatus() {
      if(this.$router.currentRoute.path !== '/') {
        return true
      }else {
        return false
      }
    },
    click_logout() {
      // this.$store.commit("LOGOUT");
      delete localStorage.accessToken
      
      if(this.$router.currentRoute.path !== '/') {
        this.$router.push('/')
        // window.scrollTo(0,0);
      }
    },
    navi(value) {
      this.$router.push(value)
    }
  },
  data() {
    return {
      drawer:false
    }
  }
}
</script>




