<template>
  <div style="margin-top:1%">
    <v-container fluid>
      <v-row>
        <v-col cols="12" xl="7" lg="7">
          <img :src="require('@/assets/status_table.jpg')" style="width:110%; height:99%"/>
        </v-col>
        <v-spacer></v-spacer>
        
        <v-col cols="12" xl="5" lg="5"> 
          <airkr></airkr>
        </v-col>
      </v-row>

      <v-data-table
    :items="recentDatas"
    item-key="deviceId"
    class = "elevation-1"
    calculate-widths
    hide-default-header
    style="margin-top:1%"
    >
      <template v-slot:header>
        <th class="msgHeader" style="background-color:#505050; height:60px">관리번호</th>
        <th class="msgHeader" style="background-color:#505050;">관리이름</th>
        <!-- <th class="msgHeader" style="background-color:#436f8a;"><div><img style="width:20%; display:inline; vertical-align:middle;" :src="require('@/assets/pm1.jpg')"/><div class="msgHeaderText">극초미세먼지/div></div></th>-->
        <th class="msgHeader" style="background-color:#436f8a;"><!-- <div><img style="width:30%; display:inline; vertical-align:middle;" :src="require('@/assets/pm25.jpg')"/>--><div class="msgHeaderText">초미세먼지<br/>(PM2.5)<!-- >/div>--></div></th>
        <th class="msgHeader" style="background-color:#436f8a;"><!-- <div><img style="width:30%; display:inline; vertical-align:middle;" :src="require('@/assets/pm100.jpg')"/>--><div class="msgHeaderText">미세먼지<br/>(PM10)<!-- >/div>--></div></th>
        <th class="msgHeader" style="background-color:#436f8a;"><!-- <div><img style="width:20%; display:inline; vertical-align:middle;" :src="require('@/assets/organic_compounds.png')"/>--><div class="msgHeaderText">휘발성유기화합물<br/>(VOC)<!-- >/div>--></div></th>
        <th class="msgHeader" style="background-color:#436f8a;"><!-- <div><img style="width:20%; display:inline; vertical-align:middle;" :src="require('@/assets/hcho.jpg')"/>--><div class="msgHeaderText">포름알데히드<br/>(HCHO)<!-- >/div>--></div></th>
        <th class="msgHeader" style="background-color:#436f8a;"><!-- <div><img style="width:20%; display:inline; vertical-align:middle;" :src="require('@/assets/radon.png')"/>--><div class="msgHeaderText">라돈<br/>(Rn)<!-- >/div>--></div></th>
        <th class="msgHeader" style="background-color:#397a73;"><!-- <div><img style="width:30%; display:inline; vertical-align:middle;" :src="require('@/assets/co2.jpg')"/>--><div class="msgHeaderText">이산화탄소<br/>(CO2)<!-- >/div>--></div></th>
        <th class="msgHeader" style="background-color:#397a73;"><!-- <div><img style="width:30%; display:inline; vertical-align:middle;" :src="require('@/assets/co.png')"/>--><div class="msgHeaderText">일산화탄소<br/>(CO)<!-- >/div>--></div></th>
        <th class="msgHeader" style="background-color:#397a73;"><!-- <div><img style="width:30%; display:inline; vertical-align:middle;" :src="require('@/assets/no2.png')"/>--><div class="msgHeaderText">이산화질소<br/>(NO2)<!-- >/div>--></div></th>
        <th class="msgHeader" style="background-color:#397a73;"><!-- <div><img style="width:30%; display:inline; vertical-align:middle;" :src="require('@/assets/o3.png')"/>--><div class="msgHeaderText">오존<br/>(O3)<!-- >/div>--></div></th>
        <th class="msgHeader" style="background-color:#397a73;"><!-- <div><img style="width:30%; display:inline; vertical-align:middle;" :src="require('@/assets/temperature.jpg')"/>--><div class="msgHeaderText">온도<br/>(Temperatura)<!-- >/div>--></div></th>
        <th class="msgHeader" style="background-color:#397a73;"><!-- <div><img style="width:30%; display:inline; vertical-align:middle;" :src="require('@/assets/humidity.jpg')"/>--><div class="msgHeaderText">습도<br/>(Humidity)<!-- >/div>--></div></th>
        <th class="msgHeader" style="background-color:#505050;"><!-- <div><img style="width:30%; display:inline; vertical-align:middle;" :src="require('@/assets/time.jpg')"/>--><div class="msgHeaderText">측정시간<!-- >/div>--></div></th>
      </template>
        
      <template v-if="load === false" v-slot:body >
        <tr> 
          <td colspan="10">
            <div class="text-lg-center pa-5" style="width: 100%;" >
              <v-progress-circular width="7" size="70" indeterminate color="red" />
            </div>
          </td>
        </tr>
      </template>

      <template  v-slot:item="props" >
        <data-row v-bind:datas="props.item" />
      </template>

    </v-data-table>
    </v-container>
  
  </div>
</template>
<style>
/* @import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);
  table * { font-family: 'NanumSquare', sans-serif; } */

  .msgHeader {
    font-size: 100%;
    font-weight:bold;
    color: #FFFFFF;
    width: 5%;
    vertical-align:middle;
  }

  table th + th { border-left:1px solid #dddddd; }
  #mytbale table{
    border: 1px solid #444444;
    border-collapse: collapse;
  }

 .combobox{
    padding: 10px; 
  }

  #status-box{
    display:inline-block; 
    width:20%; height:50%; 
    vertical-align: top; 
  }

  .status-list{
    text-align:center;
    width:20%;
    height:20%;
  }

  .status-text{
    display:inline-block;
    color:black;
    vertical-align:middle;
    margin-bottom:auto;
    margin-left:0.5vh;
    margin-right:1vh;
    position:relative;
  }

  .tiny-circle{
    margin-left: 20px;
    display:inline-block;
    width: 18%;
    display:inline-block;
    position:relative;
    vertical-align:middle;
  }

  .msgHeaderText {
    display:inline;
  }
</style>
<script>

import {
  setInterval,
  clearInterval
} from "timers";


import dataRow from "@/components/Monitoring-row"
import airkr from "@/components/Monitoring-airkr"


export default {
  name: 'Monitoring',
  components: {
    dataRow,
    airkr
  },
  created() {
    this.getRecentData()
    this.recent_timer = setInterval(this.getRecentData, 30 * 1000)
    this.getUserInfo()
    this.getContract()
  },

  beforeDestroy() {
    clearInterval(this.recent_timer)
  },
  methods: {
    getContract() {
      this.$axios.get('/user/contract').then((res) => {
        if(res.data.code === 200) {
          var current = new Date();
          var end = new Date(res.data.data.endContract);

          var diff = Math.ceil((end.getTime() - current.getTime()) / (1000 * 60 * 60 * 24));
          this.$store.commit("SET_CONTRACT",diff);
          
        }else {
          this.$store.commit("SET_CONTRACT",'?');
        }
        
        
      }).catch((err) => {
        console.log(err)
      })
      
    },
    getUserInfo() {
      this.$axios.get('/user/getAccount').then((res) => {
        if(res.data.code === 200) {
          this.$store.commit("SET_CORP_NAME",res.data.accountData.corpName);
          
        }else {
          this.$store.commit("SET_CORP_NAME",'');
        }
      }).catch((err) => {
        console.log(err)
      })
    },

    getRecentData() {
      this.$axios.get('/msg/recent').then((res) => {
        this.recentDatas = res.data.data
        this.load = true
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  data() {
    return {
      airkr_timer: null,
      recent_timer: null,

      recentDatas: [],
      load: false,
    
      airkoreaParams: {
        siName: null,
        guName: null,
      },
      airkoreaDatas: {
        pm25: null,
        pm100: null,
        date: null,
      },
      airKoreaLoc: {
        siList: [],
        guList: []
      },
    }
  }
};
</script>

