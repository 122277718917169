<template>
  <v-app>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="7"
          md="3"
        >
          <v-card class="elevation-4">
            
            <br/>
            <br/>

            <v-img
              
              width="500px"
              height="200px"
              src="@/assets/login.png"
              contain
             ></v-img>
            
            <v-spacer/>
            <v-card-text>
              <v-card-title class="headline">
                내 정보 수정
              </v-card-title>
              <v-form>
                <v-text-field
                  id="password"
                  label="Password"
                  name="password"
                  color="indigo darken-2"
                  prepend-icon="mdi-lock"
                  :type="showPass ? 'text' : 'password'"
                  v-model="member.userPass"
                  :rules="[rules.userPass.pattern]"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPass = !showPass"
                  autocomplete="off" 
                ></v-text-field>
                <v-text-field
                  id="email"
                  label="이메일"
                  name="email"
                  v-model="member.email"
                  :rules="[rules.email.pattern]"

                  color="indigo darken-2"
                  autocomplete="off" 
                ></v-text-field>
                <v-text-field
                  id="phoneNumber"
                  label="핸드폰번호"
                  name="phoneNumber"
                  v-model="member.phoneNumber"
                  color="indigo darken-2"
                  autocomplete="off" 
                ></v-text-field>
                <v-text-field
                  id="name"
                  label="이름(업체명)"
                  name="name"
                  v-model="member.name"
                  color="indigo darken-2"
                  autocomplete="off" 
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-row justify="center">
                <v-col cols="12" sm="6" md="6">
                  <v-btn class="white--text" block color="indigo darken-2"  v-on:click.native="updateAccount">수정</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
          <br>
          <div :class="`text-caption`" style="text-align: center;">
            Copyright {{this.getCurretYear()}}. <a href="https://koamise.com/" class="text-decoration-none"> 코아레스 주식회사</a> All rights reserved.
          </div>
        </v-col>
      </v-row>
      
      <join  :visible.sync="showJoinForm" />
    </v-container>
  </v-app>
</template>
<script>
    
  import Join from './Join'

  export default {
    name: 'EditAccount',
    created() {
      this.getAccount();
    },
     components: {
      Join
    },
    methods: {
      getAccount() {
        this.$axios.get("/user/getAccount").then((res)=>{
          this.member.name = res.data.accountData.userName
          this.member.phoneNumber = res.data.accountData.phoneNumber
          this.member.email = res.data.accountData.email
        })
      },
      updateAccount() {
        this.$axios.post("/user/updateAccount",this.member).then((res) => {
          console.log(res)
          if(res.data.code === 200) { 
            alert('수정이 완료되었습니다.')
          }
          else {
            alert(res.data.message)
          }
          }).catch((err) => {
          console.log(err)
        })
      },
      join() {
        this.showJoinForm=true
      },
      getCurretYear() {
        const date =new Date();
        return date.getFullYear();
      }
    },
   
    data() {
      return {
        member: {
          userPass: null,
          email: null,
          phoneNumber: null,
          name: null
        },
        showJoinForm: false,
        showPass: false,
        rules: {
        
          required: value => !!value || '필수 입력 항목입니다.',

          userPass: {
            pattern: value => {
              return (/^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,12}/.test(value))|| '영문, 숫자, 특수문자 포함 8~12자리'
            },
          },
          email: {
            pattern: value => {
              return (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) || '이메일 형식이 아닙니다.'
            }
          }
        }
      }
    }
}
</script>