<template>
  <v-app>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="7"
          md="3"
        >
          <v-card class="elevation-4">
            <v-card-title class="headline">
              비밀번호 찾기
            </v-card-title>
            <v-spacer/>
            <v-card-text>
              <v-form v-show="!isChecked">
                <v-text-field
                  id="userId"
                  label="아이디"
                  name="name"
                  color="indigo darken-2"
                  v-model="member.userId"
                ></v-text-field>
                <v-text-field
                  id="phoneNumber"
                  label="핸드폰 번호"
                  name="phoneNumber"
                  color="indigo darken-2"
                  v-model="member.phoneNumber"
                ></v-text-field>
              </v-form>
              <v-form v-show="isChecked">
                <v-text-field
                  id="authNumber"
                  label="인증번호"
                  name="authNumber"
                  color="indigo darken-2"
                  v-model="member.authNumber"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-row align="center" justify="center">
                
                <v-col cols="12" sm="6" md="6">
                  <v-btn v-show="!isChecked" class="white--text" block color="indigo darken-2"  v-on:click.native="sendAuthMsg">인증번호 발송</v-btn>
                  <v-btn v-show="isChecked" class="white--text" block color="indigo darken-2"  v-on:click.native="authFindPassword">인증하기</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
            
          </v-card>
          <br>
          <div :class="`text-caption`" style="text-align: center;">
            Copyright {{this.getCurretYear()}}. <a href="https://koamise.com/" class="text-decoration-none"> 코아레스 주식회사</a> All rights reserved.
          </div>
        </v-col>
      </v-row>
      <join  :visible.sync="showJoinForm" />
    </v-container>
  </v-app>
</template>
<script>
  import Join from './Join'

  export default {
    name: 'FindPassword',
    created() {
    },
     components: {
      Join
    },
    methods: {
      sendAuthMsg() {
        this.$axios.post("/user/sendAuthMsg",this.member).then((res) => {
            console.log(res.data)
            if(res.data.code === 200){
              this.isChecked = true
              alert('인증번호가 발송되었습니다.')
            } else {
              this.isChecked = false
              alert('이름(업체명) 또는 핸드폰 번호를 확인해주세요.')
            }
          }).catch((err) => {
            console.log(err)
          }
        )
      },

      getCurretYear() {
        const date = new Date();
        return date.getFullYear();
      },
      
      authFindPassword() {
        this.$axios.post("/user/authFindPassword",this.member).then((res) => {
          this.userId = res.data.userId;
          if(res.data.code === 200){
              alert('핸드폰으로 임시 비밀번호를 발송했습니다.')
              this.$router.push('/main')
            } else {
              alert('인증번호를 제대로 입력해주세요.')
            }
        })
      } 
    },
  
    data() {
      return {      
        member: {
          userId: null,
          phoneNumber: null,
          userPassword: null,
          authNumber: '',
          type: 'password'
        },
        isChecked: false,
        showJoinForm: false,
        showPass: false
      }
    }
  }
</script>