<template>
  <v-app>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="7" md="3">
          <v-card class="elevation-4">

            <br>
            <br>

            <v-img width="500px" height="200px" src="@/assets/login.png" contain></v-img>

            <v-spacer />
            <v-card-text>

              <v-form>
                <v-text-field
                  label="ID"
                  name="ID"
                  color="indigo darken-2"
                  prepend-icon="mdi-account"
                  type="text"
                  v-model="member.userId"
                  @keydown.enter="sign_in"
                   autocomplete="off"
                ></v-text-field>
                <v-text-field
                  id="password"
                  label="Password"
                  name="password"
                  color="indigo darken-2"
                  prepend-icon="mdi-lock"
                  :type="showPass ? 'text' : 'password'"
                  v-model="member.userPass"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showPass = !showPass"
                  @keydown.enter="sign_in"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-btn class="white--text" block color="indigo darken-2" v-on:click.native="join">회원가입</v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-btn class="white--text" block color="indigo darken-2" v-on:click.native="sign_in">로그인</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>

          </v-card>
          <v-breadcrumbs style="display: flex; justify-content: center;" :items="items" divider="|"></v-breadcrumbs>
          <br>
          <div :class="`text-caption`" style="text-align: center;">
            Copyright {{this.getCurretYear()}}. <a href="https://koamise.com/" class="text-decoration-none"> 코아레스
              주식회사</a> All rights reserved.
          </div>

        </v-col>
      </v-row>

      <terms :visible.sync="showTemrsForm" />

    </v-container>
  </v-app>
</template>
<script>
    
  
  import Terms from './Terms'

  export default {
    name: 'Login',
    created() {
      delete localStorage.accessToken
    },
     components: {
      Terms
    },
    methods: {
     
      sign_in() {
      
        if(!this.member.userId || !this.member.userPass) {
          alert('입력값을 확인해 주세요.')
        }
        else {
          this.$axios.post("/user/login",this.member).then((res) => {
            if(res.data.code === 200) { 
              this.$router.push('/main')
              // this.$store.commit("LOGIN")
            }
            else {
              alert(res.data.message)
            }
          }).catch((err) => {
             console.log(err)
          })
        }
        
      },
      join() {
        this.showTemrsForm=true
      },
      getCurretYear() {
        const date =new Date();
        return date.getFullYear();
      }
    },
   
    data() {
      return {
        items: [
          {
            text: '아이디 찾기',
            disabled: false,
            to: 'findId',
          },
          {
            text: '비밀번호 찾기',
            disabled: false,
            to: 'findPassword',
          },
        ],
        member: {
          userId: null,
          userPass: null,
        },
        showTemrsForm: false,
        showPass: false,
      }
    }
}
</script>
