<template>
  <div class="hello">
    <h1>(404) Page NotFound</h1>
  </div>
</template>

<script>
export default {
    name: 'NotFound'
};
</script>