<template>
  <v-card title align style="height:100%; width: 85%; float: right;">
    <v-card-text style="padding: 0px">
      <v-container style="padding-bottom: 0px">
        <v-row>
          <v-col cols="12" xl="5" lg="5" style="padding-top: 0px; padding-bottom: 0px">
            <v-combobox class="combobox" v-model="airkoreaParams.siName" :items="airKoreaLoc.siList"
              label="시ㆍ도" type="button" />
          </v-col>
        <v-col cols="12" xl="5" lg="5" style="padding-top: 0px; padding-bottom: 0px">
          <v-combobox class="combobox" v-model="airkoreaParams.guName" :items="setAirkrSiList()" label="시ㆍ군ㆍ구"
            type="button" />
        </v-col>
        
        <v-col cols="12" xl="2" lg="2" style="padding-top: 0px; padding-bottom: 0px">
          <v-btn style="margin:10px" class="white--text" color="indigo darken-2" @click.native="btn_airkr_click">확인 </v-btn>
        </v-col>
        </v-row>
      </v-container>
      
    </v-card-text>
  
    <div align="center" style="vertical-align: middle;">
      <p v-bind:style="{marginBottom: '6px', color: getAirkrFontColor('pm25', this.airkoreaDatas.pm25)}"> 초미세먼지 :
        {{this.airkoreaDatas.pm25}}㎍/㎥ </p>
      <p v-bind:style="{marginBottom: '6px', color: getAirkrFontColor('pm100', this.airkoreaDatas.pm100)}"> 미세먼지 :
        {{this.airkoreaDatas.pm100}}㎍/㎥ </p>
      <p style="font-weight: bold"> {{this.airkoreaDatas.date}} 기준 대기오염 현황</p>
      <p style="margin: 5px; font-size:xx-small"> 현지사정이나 수신 상태에 의해 차이가 발생할 수 있습니다. 제공 (환경부/한국환경공단)</p>
      <p style="margin: 5px; font-size:xx-small"> ― : 통신 장애 또는 점검 및 교정</p>
    </div>
  </v-card>
</template>
<script>

export default {

  created() {
    this.getAirkrSiList()
    this.getAirkrGuList()
    this.airkoreaParams.siName = localStorage.siName
    this.airkoreaParams.guName = localStorage.guName
    this.start_airkr_timer();
  },

  beforeDestroy() {
    clearInterval(this.airkr_timer);
  },
  methods: {

    getAirkrFontColor(item, value) {

      if(value === '-') {
        return "black";
      }
      if((item === 'pm25' && value > 35) ||(item === 'pm100' && value > 70)) {
        return "red";
      }
      else if((item === 'pm25' && value > 25) ||(item === 'pm100' && value > 50)) {
        return "DBC000";
      }
      else if((item === 'pm25' && value > 15) ||(item === 'pm100' && value > 30)) {
        return "green";
      }
      else {
        return "#00C6ED"
      }
    },
    setAirkrSiList() {

      try {
        if (this.airKoreaLoc.guList[this.airkoreaParams.siName].gu !== undefined) {
          return this.airKoreaLoc.guList[this.airkoreaParams.siName].gu
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    
    getAirkrSiList() {
      this.$axios.get('/airkr/list/si').then((res) => {
          this.airKoreaLoc.siList = res.data
        })
        .catch((err) => {
          console.error(err)
        })
    },
    getAirkrGuList() {
      this.$axios.get('/airkr/list/gu').then((res) => {
          this.airKoreaLoc.guList = res.data
        })
        .catch((err) => {
          this.airKoreaLoc.guList[this.airkoreaParams.siName].gu = []
          console.error(err)
        })
    },
    async btn_airkr_click() {

      localStorage.siName = this.airkoreaParams.siName;
      localStorage.guName = this.airkoreaParams.guName;

      this.start_airkr_timer()
    },
    call_airkr() {

      console.log("running airkr")
      this.$axios.get('/airkr/data', {
        params: this.airkoreaParams
      }).then(res => {
    
        this.airkoreaDatas.pm25 = res.data.pm25
        this.airkoreaDatas.pm100 = res.data.pm10
        this.airkoreaDatas.date = this.parsing_date(res.data)

      }).catch(err => {
        console.log(err)
      })
    },
    start_airkr_timer() {

      this.call_airkr()

      if (this.airkr_timer !== null) {
        clearInterval(this.airkr_timer)
      }
      this.airkr_timer = setInterval(this.call_airkr, 10 * 60 * 1000);
    },
    parsing_date(value) {
      return String(value.si) + " " + String(value.gu) + " 20" + value.time.substring(0, 2) + "년 " + value.time.substring(2, 4) + "월 " +
        value.time.substring(4, 6) + "일 " + value.time.substring(6, 8) + "시 ";
    }
  },
  data() {
    return {
      airkr_timer: null,    
      airkoreaParams: {
        siName: null,
        guName: null,
      },
      airkoreaDatas: {
        pm25: null,
        pm100: null,
        date: null,
      },
      airKoreaLoc: {
        siList: [],
        guList: []
      },
    }
  }
};
</script>

