<template>
  <v-row justify="center">
    <v-dialog max-width="1000px" v-model="visible" persistent>
      <v-form ref="form">
        <vue-daum-postcode v-on:complete="closeDialog"/> />
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>

export default {

  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    },
    address: null,
  },

  methods: {
   
    closeDialog(item) {

      this.$emit('update:visible', false);
      this.$emit('update:address', item.address);
    
    },
   
  },
}
</script>