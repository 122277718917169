import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)


export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    endContract: null,
    corpName: null,
  },
  getters: {},
  mutations: {
    SET_CONTRACT(state, value) {
      state.endContract = value
    },
    SET_CORP_NAME(state, value) {
      state.corpName = value
    }
  },
})