<template>
  <tr>
    <td class="text-center" style="font-size:110%">{{datas.mgmtId}}</td>
    <td class="text-center" style="font-size:110%">{{datas.alias}}</td>


    <!-- <td class="text-center">
      <div class="text-center">
          <div v-if="datas.pm1 <= 15" class="tiny-circle">
            
            <v-img :src="require('@/assets/blue_circle.png')" />
          </div>
          <div v-else-if="datas.pm11 <= 25" class="tiny-circle">
            <v-img :src="require('@/assets/green_circle.png')" />
          </div>
          <div v-else-if="datas.pm1 <= 35" class="tiny-circle">
            <v-img :src="require('@/assets/yellow_circle.png')" />
          </div>
          <div v-else class="tiny-circle">
            <v-img :src="require('@/assets/red_circle.png')" />
          </div>
          <div class="status-text" style="width:30%; font-size:120%; margin-left:10%;">{{datas.pm1}}</div>
      </div>
    </td> -->

    <td class="text-center">

    <div class="text-center">
        <div v-if="datas.pm25 <= 15" class="tiny-circle">
          
          <v-img :src="require('@/assets/blue_circle.png')" />
        </div>
        <div v-else-if="datas.pm25 <= 25" class="tiny-circle">
          <v-img :src="require('@/assets/green_circle.png')" />
        </div>
        <div v-else-if="datas.pm25 <= 35" class="tiny-circle">
          <v-img :src="require('@/assets/yellow_circle.png')" />
        </div>
        <div v-else class="tiny-circle">
          <v-img :src="require('@/assets/red_circle.png')" />
        </div>
        <div class="status-text" style="width:25%; font-size:120%;">{{datas.pm25}}</div>
      </div>
    </td>

    <td class="text-center">
      <div class="text-center">
        <div v-if="datas.pm100 <= 30" class="tiny-circle">
          <v-img :src="require('@/assets/blue_circle.png')" />
        </div>
        <div v-else-if="datas.pm100 <= 50" class="tiny-circle">
          <v-img :src="require('@/assets/green_circle.png')" />
        </div>
        <div v-else-if="datas.pm100 <= 70" class="tiny-circle">
          <v-img :src="require('@/assets/yellow_circle.png')" />
        </div>
        <div v-else class="tiny-circle">
          <v-img :src="require('@/assets/red_circle.png')" />
        </div>
        <div class="status-text" style="width:25%; font-size:110%;">{{datas.pm100}}</div>
      </div>
    </td>



    <td class="text-center">
      <div>
        <div v-if="datas.tvoc <= 100" class="tiny-circle">
          <v-img :src="require('@/assets/blue_circle.png')" />  
        </div>
        <div v-else-if="datas.tvoc <= 600" class="tiny-circle">
          <v-img :src="require('@/assets/green_circle.png')" />
        </div>
        <div v-else-if="datas.tvoc <= 1200" class="tiny-circle">
          <v-img :src="require('@/assets/yellow_circle.png')" />
        </div>
        <div v-else class="tiny-circle">
          <v-img :src="require('@/assets/red_circle.png')" />
        </div>
        <div class="status-text" style="width:40%; font-size:110%">{{datas.tvoc}}</div>
      </div>
    </td>

    <td class="text-center">
      <div>
        <div v-if="datas.hcho <= 100" class="tiny-circle">
          <v-img :src="require('@/assets/blue_circle.png')" />  
        </div>
        <div v-else-if="datas.hcho <= 600" class="tiny-circle">
          <v-img :src="require('@/assets/green_circle.png')" />
        </div>
        <div v-else-if="datas.hcho <= 1200" class="tiny-circle">
          <v-img :src="require('@/assets/yellow_circle.png')" />
        </div>
        <div v-else class="tiny-circle">
          <v-img :src="require('@/assets/red_circle.png')" />
        </div>
        <div class="status-text" style="width:40%; font-size:110%">{{datas.hcho}}</div>
      </div>
    </td>

    <td class="text-center">
      <div>
        <div v-if="datas.radon <= 100" class="tiny-circle">
          <v-img :src="require('@/assets/blue_circle.png')" />  
        </div>
        <div v-else-if="datas.radon <= 600" class="tiny-circle">
          <v-img :src="require('@/assets/green_circle.png')" />
        </div>
        <div v-else-if="datas.radon <= 1200" class="tiny-circle">
          <v-img :src="require('@/assets/yellow_circle.png')" />
        </div>
        <div v-else class="tiny-circle">
          <v-img :src="require('@/assets/red_circle.png')" />
        </div>
        <div class="status-text" style="width:40%; font-size:110%">{{datas.radon}}</div>
      </div>
    </td>

    <td class="text-center">
      <div>
        <div v-if="datas.co2 <= 100" class="tiny-circle">
          <v-img :src="require('@/assets/blue_circle.png')" />  
        </div>
        <div v-else-if="datas.co2 <= 600" class="tiny-circle">
          <v-img :src="require('@/assets/green_circle.png')" />
        </div>
        <div v-else-if="datas.co2 <= 1200" class="tiny-circle">
          <v-img :src="require('@/assets/yellow_circle.png')" />
        </div>
        <div v-else class="tiny-circle">
          <v-img :src="require('@/assets/red_circle.png')" />
        </div>
        <div class="status-text" style="width:40%; font-size:110%">{{datas.co2}}</div>
      </div>
    </td>


    <td class="text-center">
      <div>
        <div v-if="datas.co <= 700" class="tiny-circle">
          <v-img :src="require('@/assets/blue_circle.png')" />  
        </div>
        <div v-else-if="datas.co <= 1000" class="tiny-circle">
          <v-img :src="require('@/assets/green_circle.png')" />
        </div>
        <div v-else-if="datas.co <= 2000" class="tiny-circle">
          <v-img :src="require('@/assets/yellow_circle.png')" />
        </div>
        <div v-else class="tiny-circle">
          <v-img :src="require('@/assets/red_circle.png')" />
        </div>
        <div class="status-text" style="width:40%; font-size:110%;">{{datas.co}}</div>
      </div>
    </td>

    <td class="text-center">
      <div>
        <div v-if="datas.no2 <= 700" class="tiny-circle">
          <v-img :src="require('@/assets/blue_circle.png')" />  
        </div>
        <div v-else-if="datas.no2 <= 1000" class="tiny-circle">
          <v-img :src="require('@/assets/green_circle.png')" />
        </div>
        <div v-else-if="datas.no2 <= 2000" class="tiny-circle">
          <v-img :src="require('@/assets/yellow_circle.png')" />
        </div>
        <div v-else class="tiny-circle">
          <v-img :src="require('@/assets/red_circle.png')" />
        </div>
        <div class="status-text" style="width:40%; font-size:110%;">{{datas.no2}}</div>
      </div>
    </td>

    <td class="text-center">
      <div>
        <div v-if="datas.o3 <= 700" class="tiny-circle">
          <v-img :src="require('@/assets/blue_circle.png')" />  
        </div>
        <div v-else-if="datas.o3 <= 1000" class="tiny-circle">
          <v-img :src="require('@/assets/green_circle.png')" />
        </div>
        <div v-else-if="datas.o3 <= 2000" class="tiny-circle">
          <v-img :src="require('@/assets/yellow_circle.png')" />
        </div>
        <div v-else class="tiny-circle">
          <v-img :src="require('@/assets/red_circle.png')" />
        </div>
        <div class="status-text" style="width:40%; font-size:110%;">{{datas.o3}}</div>
      </div>
    </td>

    <td class="text-center" style="font-size:110%">{{datas.temperature}}</td>
    <td class="text-center" style="font-size:110%">{{datas.humidity}}</td>
    <td class="text-center" style="font-size:105%" v-bind:style="{color: fontColor}">{{datas.publishTime}}</td>
  </tr>
</template>

<script>

export default {
  props: ['datas'],
  watch:{
    datas: {
      handler: function(datas) {
        
        let publishTime = new Date(datas.publishTime)
        let now = new Date();
        var timeDiff = (now - publishTime)
        if(timeDiff>(1000 * 60 * 10)){
          this.fontColor = "red"
          console.log('More Than 10 min')
        }else{
          this.fontColor = "black"
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      timer: null,
      fontColor: "black",
    }
  }
};
</script>
