<template>
  <v-app>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="7"
          md="3"
        >
          <v-card class="elevation-4">
            
            <br/>
            <br/>

            <v-img
              
              width="500px"
              height="200px"
              src="@/assets/login.png"
              contain
             ></v-img>
            
            <v-spacer/>
            <v-card-text>
              <v-card-title class="headline">
                회원 탈퇴
              </v-card-title>
            </v-card-text>
            <v-card-actions>
              <v-row justify="center">
                <v-col cols="12" sm="6" md="6">
                  <v-btn class="white--text" block color="indigo darken-2"  v-on:click.native="withDrawal">탈퇴</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
          <br>
          <div :class="`text-caption`" style="text-align: center;">
            Copyright {{this.getCurretYear()}}. <a href="https://koamise.com/" class="text-decoration-none"> 코아레스 주식회사</a> All rights reserved.
          </div>
        </v-col>
      </v-row>
      
      <join  :visible.sync="showJoinForm" />
    </v-container>
  </v-app>
</template>
<script>
    
  import Join from './Join'

  export default {
    name: 'EditAccount',
    created() {
      this.getAccount();
    },
     components: {
      Join
    },
    methods: {
      withDrawal() {
        this.$axios.post("/user/withDrawal").then((res) => {
          console.log(res)
            if(res.data.code === 200) { 
              alert('회원이 탈퇴되었습니다.')
              delete localStorage.accessToken
              
              if(this.$router.currentRoute.path !== '/') {
                this.$router.push('/')
                // window.scrollTo(0,0);
              }
            }
          }).catch((err) => {
          console.log(err)
        })
      },
      join() {
        this.showJoinForm=true
      },
      getCurretYear() {
        const date =new Date();
        return date.getFullYear();
      },
    },
   
    data() {
      return {
        member: {
          userPass: null,
          email: null,
          phoneNumber: null,
          name: null
        },
        showJoinForm: false,
        showPass: false,
      }
    }
}
</script>