<template>
  <div style="margin-top:3%">
    <v-dialog v-model="addDialog" max-width="700px">
      <v-card>
        <v-alert v-model="alertDis" type="error">
          허용된 기기가 아닙니다.
        </v-alert>
        <v-alert v-model="alertDup" type="error">
          기존 기기와 겹치는 항목이 있습니다.
        </v-alert>
        <v-alert v-model="alertSerialDup" type="error">
          이미 사용 중인 시리얼 번호입니다.
        </v-alert>
        <v-alert v-model="alertBla" type="error">
          빠짐없이 입력해주세요.
        </v-alert>
        <v-alert v-model="alertErr" type="error">
          실패하였습니다.
        </v-alert>

        <v-card-title>
          기기 추가
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" xl="4" lg="4">
                <v-text-field autocomplete="off" v-model="addItem.deviceId" label="시리얼번호" counter="16" maxlength="16">
                </v-text-field>
              </v-col>
              <v-col cols="12" xl="4" lg="4">
                <v-text-field autocomplete="off" v-model="addItem.mgmtId" label="관리번호" counter="20" maxlength="20">
                </v-text-field>
              </v-col>
              <v-col cols="12" xl="4" lg="4">
                <v-text-field autocomplete="off" v-model="addItem.alias" label="관리이름" counter="20" maxlength="20">
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.stop="addDialog=false">취소</v-btn>
          <v-btn color="primary" text @click.native="addDevice">완료</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editDialog" max-width="700px">
      <v-card>
        <v-alert v-model="alertDup" type="error" dismissible>
          기존 기기와 겹치는 항목이 있습니다.
        </v-alert>
        <v-alert v-model="alertBla" type="error" dismissible>
          빠짐없이 입력해주세요.
        </v-alert>
        <v-alert v-model="alertErr" type="error" dismissible>
          실패하였습니다.
        </v-alert>
        <v-card-title>
          기기 수정
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" xl="4" lg="4">
                <v-text-field v-model="editedItem.deviceId" label="시리얼번호" disabled></v-text-field>
              </v-col>
              <v-col cols="12" xl="4" lg="4">
                <v-text-field v-model="editedItem.mgmtId" label="관리번호"></v-text-field>
              </v-col>
              <v-col cols="12" xl="4" lg="4">
                <v-text-field v-model="editedItem.alias" label="관리이름"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click.stop="editDialog=false">취소</v-btn>
          <v-btn color="primary" text @click.native="editDevice">완료</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-container style="padding: 2%">
      <v-card tile align>
        <v-card-actions style="padding-top:2%;">
          <v-row>
            <v-col cols="12" xl="1" lg="1" md="1" />

            <v-col cols="12" xl="9" lg="7" md="7">      
              
              <h1> 측정기 목록 </h1>
            </v-col>
            
            <v-col cols="12" xl="1" lg="2" md="2">
              <v-btn @click.native="openAddDialog">기기 추가</v-btn>
            </v-col>

            <v-col cols="12" xl="1" lg="2" md="2">
              <v-btn @click.native="deleteDevice">기기 삭제</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        <v-container fluid>
          <v-data-table v-model="selected" :headers="headers" :items="devices" item-key="deviceId" show-select
            class="mytable">
            <template v-slot:[`item.sort`]="{ item }">
              <v-btn icon @click="clickSort(item.deviceId, 'up')" v-if="item.deviceId != devices[0].deviceId">
                <v-icon color="indigo darken-2">mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn icon @click="clickSort(item.deviceId, 'down')"
                v-if="item.deviceId != devices[(devices.length-1)].deviceId">
                <v-icon color="indigo darken-2">mdi-arrow-down</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <v-btn icon class="mx-0" @click="openEditDialog(item)">
                <img :src="require('@/assets/editIcon.png')" />
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  
  export default {
    
    created() {
      this.getDevices(0);
    },
    methods: {
      clickSort(deviceId, direction) {

        var device_index;

        this.devices.forEach((value,index) => {
          if(value.deviceId === deviceId) {
            console.log(index)
            device_index = index;
            return;
          }
        })
        
        this.updateDeviceOrder(device_index, direction, this.devices[device_index].deviceId)
        const rowSelected = this.devices.splice(device_index, 1)[0];

        if(direction === 'up') {
          this.devices.splice(device_index - 1, 0, rowSelected);
        }else {
          this.devices.splice(device_index + 1, 0, rowSelected);
        }
        
      },
      updateDeviceOrder(index, direction, deviceId) {

        const param = {
          index: index,
          direction: direction,
          deviceId: deviceId
        }

        this.$axios.post("/device/update/sort", param).catch((err) => {
          console.log(err)
        })
      },
      updateDeviceOrderAll(devices) {
        devices.forEach((device, index) => {
          device = Object.assign(device, {
            "index": index
          });
           this.$axios.post("/device/update/sort/all", device)
          .catch((err) => {
              console.error(err)
            })
        })
      },
      getDevices(order) {

        this.$axios.get("/device/info").then((res) => {
          this.devices = res.data.data
          if(order === 1) {
            this.updateDeviceOrderAll(this.devices)
          }
        })
        .catch((err) => {
          console.error(err)
        })

      },
      altReset() {
        this.alertDis = false;
        this.alertDup = false;
        this.alertBla = false;
        this.alertErr = false;
        this.alertSerialDup = false;
      },
      openEditDialog(item) {

        this.altReset()
        this.editedItem = Object.assign({}, item)
        this.editedIndex = this.devices.indexOf(item)
        this.editDialog = true
        
      },
      openAddDialog() {
        this.altReset()
        this.addItem.deviceId = ""
        this.addItem.mgmtId = ""
        this.addItem.alias = ""
        this.addDialog = true
      },
      deleteDevice() {

        if (this.selected.length === 0) {
          alert("삭제할 기기를 선택해주세요.")
        } 
        else {

          var list = [];
          this.selected.forEach((value) => {
            list.push(value.deviceId)
          });

          var cf = confirm("정말 삭제하시겠습니까?");
          if(cf){
          
            this.$axios.post("/device/delete", { deviceId: list }).then((res) => {
              if (res.data.code === 200) {
                alert('삭제되었습니다.')
                this.getDevices(1) 
              }
              else {
                alert('삭제 실패')
              }
            })
          } 
        }
        
      },
      editDevice() {
        if (this.editedIndex > -1) {

          var check = 0;

          if (this.editedItem.alias === "") {
            this.altReset()
            this.alertBla = true;
            check = 1;
          } else {

            this.devices.forEach((value) => {
              if (value.alias === this.editedItem.alias) {
                this.altReset()
                this.alertDup = true;
                check = 1;
                return;
              }
            });
          }
          if (check === 0) {

            this.$axios.post("/device/update", {
              deviceId: this.editedItem.deviceId,
              mgmtId: this.editedItem.mgmtId,
              alias: this.editedItem.alias
            }).then((res) => {
              if (res.data.code === 200) {
                this.getDevices(0)
                this.editDialog = false
              } else {
                this.altReset()
                this.alertErr = true;
              }
            })
          } 
        }
      },
      addDevice() {

        var check = 0;

        if (this.addItem.deviceId === "" || this.addItem.mgmtId === "" || this.addItem.alias === "") {
          this.altReset()
          this.alertBla = true;

          check = 1;

        } else {

          this.devices.forEach((value) => {
            if (value.deviceId.trim() === this.addItem.deviceId.trim() ||
              value.mgmtId.trim() === this.addItem.mgmtId.trim() ||
              value.alias.trim() === this.addItem.alias.trim()) {

              this.altReset()
              this.alertDup = true;
              check = 1;

              return;
            }
          });
        }
        if (check === 0) {

          this.$axios.post("device/create", {
              deviceId: this.addItem.deviceId,
              mgmtId: this.addItem.mgmtId,
              alias: this.addItem.alias
            }, {
              "Content-Type": "application/json"
            })
            .then((res) => {
              if (res.data.code === 200) {
                this.addDialog = false
                alert('추가되었습니다.')
                this.getDevices(0)
              } else if (res.data.code === 300) {
                this.altReset()
                this.alertDis = true;
              } else if (res.data.code === 400) {
                this.altReset()
                this.alertSerialDup = true;
              }
               else {
                this.altReset()
                this.alertErr = true;
              }
            })
        }
      }
    },
    data() {
      return {
        isAllClick: false,

        alertDis: false,
        alertDup: false,
        alertBla: false,
        alertSerialDup: false,
        alertErr: false,

        editedItem: {},
        addItem: {},
        editedIndex: -1,
        editDialog: false,
        addDialog: false,

        selected: [],
        devices: [],

        headers: [
          {
            text: '시리얼 번호',
            align: 'center',
            sortable: false,
            value: 'deviceId'
          },
           {
            text: '기기 번호',
            align: 'center',
            sortable: false,
            value: 'mgmtId'
          },
           {
            text: '별칭',
            align: 'center',
            sortable: false,
            value: 'alias'
          },
           {
            text: '정렬',
            align: 'center',
            sortable: false,
            value: 'sort'
          },
          {
          text: '편집',
          align: 'center',
          sortable: false,
          value: 'edit'
        },
        ],
      }
    }
  }
</script>

<style scoped>



::v-deep .v-data-table th{ 
  background-color:#ebebeb;
  font-weight:bold;
  color:black !important;
  font-size: 18px !important;
}

</style>




