import Vue from 'vue'
import router from './router'
import vuetify from './plugins/vuetify';
import axios from './plugins/axios'
import App from './App'
import store from './store'
import vuetifyDialog from 'vuetify-dialog'
import VueDaumPostcode from "vue-daum-postcode"
import 'vuetify/dist/vuetify.min.css';
import 'vuetify-dialog/dist/vuetify-dialog.css'

Vue.config.productionTip = false

Vue.prototype.$http = axios

Vue.use(VueDaumPostcode)
Vue.use(vuetify)
Vue.use(axios)
Vue.use(vuetifyDialog, {
  context: {
    vuetify
  }
})

new Vue({
  el : '#app',
  store,  
  router,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')

