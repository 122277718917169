import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import Monitoring from '@/components/Monitoring'
import Device from '@/components/Device'
import Stat from '@/components/Stat'
import NotFound from '@/components/NotFound'
import FindId from '@/components/FindId'
import FindPassword from '@/components/FindPassword'
import EditAccount from '@/components/EditAccount'
import WithDrawal from '@/components/WithDrawal'
// import Alarm from '@/components/Alarm'


// import store from '@/store'

Vue.use(Router)


const requireAuth = () => (from, to, next) => {
    
    if (localStorage.getItem("accessToken")) return next() 
    next('/') 
  }

export default new Router({
    mode:'hash',
    routes :[
        {
            path: '/',
            component: Login
        },
        {
            path: '/main',
            component: Monitoring,
            beforeEnter: requireAuth()
        },
        {
            path: '/device',
            component: Device,
            beforeEnter: requireAuth()
        },
        {
            path: '/findId',
            component: FindId,
        },
        {
            path: '/findPassword',
            component: FindPassword,
        },
        {
            path: '/editAccount',
            component: EditAccount,
            beforeEnter: requireAuth()
        },
        {
            path: '/withDrawal',
            component: WithDrawal,
            beforeEnter: requireAuth()
        },
        {
            path: '/stat',
            component: Stat,
            beforeEnter: requireAuth()
        },
        {
            path: '*',
            component: NotFound,
            beforeEnter: requireAuth()
        },
    ]
}) 


