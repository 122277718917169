<template>
  <v-row justify="center">
    <v-dialog max-width="800px" v-model="visible" persistent>
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <br>
            <br>
            <br>
            <v-img width="100px" height="50px" src="@/assets/koares_logo.jpg" contain></v-img>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-checkbox color="indigo darken-2" @click="allClick" v-model="chkAll" label="코아레스 이용약관, 개인정보 수집 및 이용에 모두 동의합니다."> </v-checkbox>

              <v-checkbox color="indigo darken-2" v-model="chkTemrs" label="코아레스 이용약관(필수)"> </v-checkbox>
              <v-textarea no-resize readonly solo v-model="txtTemrs"></v-textarea>
              
              <v-checkbox color="indigo darken-2" v-model="chkuserInfo" label="개인정보 수집 및 이용 동의(필수)"> </v-checkbox>
              <v-textarea no-resize readonly solo v-model="txtAgreeUserInfo"></v-textarea>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="indigo darken-2" text @click="close">닫기</v-btn>
            <v-btn color="indigo darken-2" text @click="submit">확인</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <join :visible.sync="showJoinForm" />

  </v-row>
</template>

<script>

import Join from './Join'

export default {

  created() {
    this.getTxtTemrs()
    this.getTxtAgreeUserInfo()
  },
  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  components: {
    Join
  },
  watch:{

    chkTemrs(val){
      if(val ===  true && this.chkuserInfo === true) {
        this.chkAll = true
      }else {
        this.chkAll = false
      }
    },
    chkuserInfo(val){
      if(val ===  true && this.chkTemrs === true) {
        this.chkAll = true
      }else {
        this.chkAll = false
      }
    },
    
  },
  methods: {

    allClick() {
      if(this.chkAll === true) {
        this.chkTemrs = true
        this.chkuserInfo = true
      }else {
        this.chkTemrs = false
        this.chkuserInfo = false
      }
    },
    getTxtTemrs() {
      this.$axios.get('/text/terms').then((res) => {
        this.txtTemrs = res.data
      })
      .catch((err) => {
        alert(err)
      });
    },
    getTxtAgreeUserInfo() {
      this.$axios.get('/text/agreeUserInfo').then((res) => {
        this.txtAgreeUserInfo = res.data
        
      })
      .catch((err) => {
        alert(err)
      });
    },
    
    close() {
      this.chkTemrs = false
      this.chkuserInfo = false
      this.$emit('update:visible', false);
    },
    submit() {
      if(this.chkAll === true) {
        this.close()
        this.showJoinForm = true
      }else{
        alert('필수 항목에 동의해주세요.')
      }
    }
  },
  data() {
    return {

      chkAll:false,
      chkTemrs:false,
      chkuserInfo:false,
      txtTemrs: null,
      txtAgreeUserInfo: null,
      showJoinForm: false
    }
  }
}
</script>
