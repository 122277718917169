<template>
  <v-row justify="center">
    <v-dialog max-width="800px" v-model="visible" persistent>
      <v-form ref="form">
        <v-card>
          <v-card-title>
            
            <br>
            <br>
            <br>
            <v-img width="100px" height="50px" src="@/assets/koares_logo.jpg" contain></v-img>
          
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4" md="6">
                  <v-text-field prepend-icon="mdi-account" label="아이디" color="indigo darken-2" v-model="member.userId"
                    counter="10" maxlength="10" autocomplete="off" :rules="[rules.userId.pattern]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="6">
                  <v-text-field prepend-icon="mdi-home-account" label="기업명" color="indigo darken-2"
                    v-model="member.corpName" autocomplete="off" :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>

              <v-row >
                <v-col cols="12" sm="4" md="6">
                  <v-text-field prepend-icon="mdi-lock" label="비밀번호" color="indigo darken-2" type="password"
                    v-model="member.userPass" counter="12" maxlength="12" autocomplete="off" :rules="[rules.userPass.pattern]">
                  </v-text-field>

                </v-col>
                <v-col cols="12" sm="4" md="6">
                  <v-text-field prepend-icon="mdi-lock" label="비밀번호 확인" color="indigo darken-2" type="password"
                    v-model="password_cf" counter="12" maxlength="12" autocomplete="off" :rules="[rules.required]"></v-text-field>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col sm="4" md="6">
                  <v-text-field prepend-icon="mdi-account-tie" label="대표자명" color="indigo darken-2"
                    v-model="member.bossName" autocomplete="off" :rules="[rules.required]"></v-text-field>
                </v-col>


                <v-col sm="4" md="2">
                  <v-text-field  prepend-icon="mdi-card-account-details" label="사업자 번호" color="indigo darken-2"
                    autocomplete="off" :rules="[rules.bizNum.required,,rules.bizNum.pattern]" v-model="member.bizNum[0]"  counter="3" maxlength="3"></v-text-field>
                </v-col>

                <h2 style="margin-right:10px; margin-left:13px">-</h2>

                <v-col sm="4" md="1">
                  <v-text-field  color="indigo darken-2" v-model="member.bizNum[1]"
                    autocomplete="off" :rules="[rules.bizNum.required,,rules.bizNum.pattern]" counter="2" maxlength="2"></v-text-field>
                </v-col>

                <h2 style="margin-right:10px; margin-left:13px">-</h2>

                <v-col sm="4" md="2">
                  <v-text-field   color="indigo darken-2" v-model="member.bizNum[2]"
                    autocomplete="off" :rules="[rules.bizNum.required,,rules.bizNum.pattern]" counter="5" maxlength="5"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="4" md="5">
                  <v-text-field prepend-icon="mdi-map-marker" label="주소" color="indigo darken-2"
                    v-model="member.address" autocomplete="off" :rules="[rules.required]" readonly>
                  </v-text-field>

                </v-col>

                <v-col cols="12" sm="2" md="1" align-self="center">
                  <v-btn color="indigo darken-2" text @click="openPostCode">검색</v-btn>
                </v-col>

                <v-col cols="12" sm="4" md="6">
                  <v-text-field prepend-icon="mdi-map-marker" label="상세 주소" color="indigo darken-2"
                    v-model="member.addressDetail" autocomplete="off" :rules="[rules.required]">
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="4" md="6">
                  <v-text-field prepend-icon="mdi-account" label="담당자명" color="indigo darken-2"
                    v-model="member.userName" autocomplete="off" :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field prepend-icon="mdi-cellphone" label="담당자 연락처" color="indigo darken-2"
                    v-model="member.phoneNumber" autocomplete="off" :rules="[rules.required,rules.phoneNumber.pattern]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="1" align-self="center">
                  <v-btn color="indigo darken-2" text @click="sendAuthMsg">인증 요청</v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="4" md="6">
                  <v-text-field prepend-icon="mdi-card-account-mail" label="이메일" color="indigo darken-2"
                    v-model="member.email" autocomplete="off" :rules="[rules.required, rules.email.pattern]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="6">
                  <v-text-field prepend-icon="mdi-lock" label="핸드폰 본인인증 암호" color="indigo darken-2"
                    v-model="member.authPass" autocomplete="off" :rules="[rules.required, rules.authPass.pattern]"></v-text-field>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="indigo darken-2" text @click="closeDialog">닫기</v-btn>
            <v-btn color="indigo darken-2" text @click="requestJoinMemer">가입</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <postCode :visible.sync="showPostCode" :address.sync="member.address" />

  </v-row>
</template>

<script>
import postCode from '@/components/PostCodeDialog'

export default {

  props: {
    visible: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  components: {
    postCode
  },

  methods: {

    openPostCode() {
      this.showPostCode = true;
    },
    requestJoinMemer() {

      if (this.$refs.form.validate()) {

        if (this.member.userPass !== this.password_cf) {
          alert("비밀번호와 비밀번호 확인이 일치하지 않습니다.")
        } else if (this.authPassServer === null || (this.member.authPass !== this.authPassServer)) {
          alert("본인 인증 암호가 일치하지 않습니다.")
        } else {

          this.$axios.post('/user/join', this.member).then(res => {
              console.log(res);
              alert(res.data.message)

              if (res.data.code === 200) {
                this.closeDialog()
              }
            })
            .catch((err) => {
              alert(err)
            });
        }
      }
    },
    closeDialog() {
      this.$emit('update:visible', false);
      this.$refs.form.reset();
      this.authPassServer = null;
    },
    sendAuthMsg() {

      this.$axios.post("/user/sendAuthMessage", this.member, {}).then(res => {
        if (res.data.code === 200) {
          alert("인증번호가 전송되었습니다. \n 유효기간 5분")
          this.authPassServer = String(res.data.authPass)
          setTimeout(() => this.authPassServer = null, 5 * 60 * 1000)
        } else {
          alert("핸드폰 번호를 확인해 주세요")
        }
      }).catch(err => {
        console.log(err)
      })
    }
  },
  data() {
    return {

      showPostCode: false,

      member: {
        userId: null,
        userPass: null,
        corpName: null,
        userName: null,
        email: null,
        phoneNumber: null,
        authPass: null,
        address: null,
        addressDetail: null,
        bizNum: [null, null, null]
      },
      authPassServer: null,
      password_cf: null,
      rules: {

        required: value => !!value || '필수 입력 항목입니다.',

        userId: {
          pattern: value => {
            return (/^(?=.*[a-z])(?=.*\d)[a-z\d]{6,10}/.test(value)) || '영문, 숫자,포함 6~10자리'
          },
        },
        userPass: {
          pattern: value => {
            return (/^(?=.*[a-z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,12}/.test(value)) || '영문, 숫자, 특수문자 포함 8~12자리'
          },
        },
        email: {
          pattern: value => {
            return (
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              .test(value)) || '이메일 형식이 아닙니다.'
          }
        },
        phoneNumber: {
          pattern: value => {
            return (/^[0-9]{10,11}$/.test(value)) || '10~11 자리 숫자만 입력 가능합니다.'
          }
        },
        authPass: {
          pattern: value => {
            return (/^[0-9]{4}$/.test(value)) || '인증 암호 4자리를 입력해주세요.'
          }
        },
        bizNum: {
          required: value => !!value || '',
          pattern: value => {
            return (/^[0-9]+$/.test(value)) || ''
          }
        },
      }
    }
  }
}
</script>